import React, { useEffect, useState } from "react";
import { FaDownload } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../assets/images/logounit.png";
import AddressModal from "../components/AddressModal";
import ShareModal from "../components/ShareModal";
import HeroMessageModal from "../components/HeroMessageModal";
import { useGetUserAvatarQuery } from "../store/avatar/avatarApi";
import VideoMP from "../assets/images/BG v1.mp4";

function OutputPage() {
  const userInfo = useSelector((state) => state.auth?.userInfo);
  const AvatarData = useSelector((state) => state.avatar?.mediaData);
  const storageImage = AvatarData?.avtar;
  const isLoggedIn = !!userInfo;
  const token = userInfo?.token;
  const navigate = useNavigate();

  const {
    data: avatarsResponse,
    isLoading: isAvatarLoading,
    refetch: refetchAvatar,
  } = useGetUserAvatarQuery(token || "", {
    skip: !isLoggedIn,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [heroType, setHeroType] = useState("");

  // Function to check image availability
  const checkImageAvailability = () => {
    const img = new Image();
    img.src = storageImage;

    img.onload = () => {
      setIsImageLoaded(true); // Image is available and loaded
    };

    img.onerror = () => {
      setTimeout(checkImageAvailability, 1000); // Retry after 1 second if image isn't available
    };
  };

  useEffect(() => {
    if (!storageImage) {
      navigate("/");
    } else {
      checkImageAvailability(); // Check if image is available
    }
  }, [storageImage, navigate]);

  // Set hero type after image loads
  useEffect(() => {
    if (AvatarData?.hero && isImageLoaded) {
      const heroName = AvatarData?.hero || "Zingerman";
      switch (heroName) {
        case "SaverB":
          setHeroType("SaverB");
          break;
        case "The Big Piece":
          setHeroType("BigPiece");
          break;
        case "Crispy King":
          setHeroType("CrispyKing");
          break;
        case "Crispy Queen":
          setHeroType("CrispyQueen");
          break;
        case "Zingerwoman":
          setHeroType("ZingerWoman");
          break;
        default:
          setHeroType("Zingerman");
      }
    }
  }, [avatarsResponse, isImageLoaded]);

  // Open message modal after 2 seconds when image is loaded
  useEffect(() => {
    if (isImageLoaded) {
      setTimeout(() => {
        setIsMessageModalOpen(true);
      }, 2000); // Show message modal after 2 seconds
    }
  }, [isImageLoaded]);

  const handleDownload = () => {
    const imageUrl = storageImage || "";
    if (imageUrl) {
      const link = document.createElement("a");
      link.href = imageUrl;
      link.download = "hero-avatar.png";
      link.click();
    }
  };

  return (
    <div className="output">
      <video autoPlay muted loop playsInline className="video-background">
        <source src={VideoMP} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <Link to="/">
        <img src={Logo} className="logo-unit" alt="logo" />
      </Link>
      <div className="output-wrapper container">
        <div className="output-item">
          <h2 className="s-heading">
            Behold for Your <br /> EPIC heroic transformation!
          </h2>
          <p className="s-copy">
            Ready to take on{" "}
            <strong>{avatarsResponse?.data[0]?.villain}</strong>?
          </p>
          <p className="s-copy">
            Head to your nearest KFC to fuel up for the battle!
          </p>
          <div className="s-btn-wrap">
            <button className="s-btn" onClick={() => setIsModalOpen(true)}>
              Share
            </button>
            {/* <button
              className="s-btn"
              onClick={() => setIsAddressModalOpen(true)}
            >
              Claim your custom bucket
            </button> */}
          </div>
        </div>

        <div
          className={
            isImageLoaded ? "output-item-img avatar-applied" : "output-item-img"
          }
          style={{ position: "relative" }}
        >
          {!isImageLoaded ? (
            <div className="spinner"></div>
          ) : (
            <>
              <img
                src={storageImage}
                alt="Uploaded"
                style={{ maxWidth: "100%", borderRadius: "10px" }}
                className="result-image"
              />
              <button onClick={handleDownload} className="download-btn">
                <FaDownload />
              </button>
            </>
          )}
        </div>
      </div>

      {/* Share Modal */}
      <ShareModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />

      {/* Address Modal */}
      {/* <AddressModal
        isOpen={isAddressModalOpen}
        onClose={() => setIsAddressModalOpen(false)}
        avatarData={storageImage}
        isImageLoaded={isImageLoaded}
      /> */}

      {/* Hero Message Modal */}
      <HeroMessageModal
        isOpen={isMessageModalOpen}
        onClose={() => setIsMessageModalOpen(false)}
        heroType={heroType}
      />
    </div>
  );
}

export default OutputPage;
