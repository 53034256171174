import React, { useEffect, useState } from "react";

function HeroMessageModal({ isOpen, onClose, heroType }) {
  const [message, setMessage] = useState("");

  const heroMessages = {
    SaverB: "Welcome SaverB!\n Your bucket-protecting powers are just what we need to keep everyone's KFC safe and shared.\n Thank you for joining the KFC Epic Hero Squad.\n Stay tuned for exciting prizes!",
    BigPiece: "The Big Piece has arrived!\n Ready to defend the meatiest, juiciest pieces in every bucket.\n Thank you for joining the KFC Epic Hero Squad.\n Stay tuned for exciting prizes!",
    CrispyKing: "All hail our new Crispy King!\n The strips and popcorn are safe under your crispy reign.\n Thank you for joining the KFC Epic Hero Squad.\n Stay tuned for exciting prizes!",
    CrispyQueen: "All hail our new Crispy Queen! \n The strips and popcorn are safe under your crispy reign.\n Thank you for joining the KFC Epic Hero Squad.\n Stay tuned for exciting prizes!",
    Zingerman: "Thank you, Zingerman, for joining the fight!\n Time to keep our burgers crunchy and our rolls zinging.\n Stay tuned for exciting prizes!",
    ZingerWoman: "Thank you, Zingerwoman, for joining the fight!\n Time to keep our burgers crunchy and our rolls zinging.\n Stay tuned for exciting prizes!"
  };

  useEffect(() => {
    if (heroType) {
      setMessage(heroMessages[heroType] || "Heroic message not available.");
    }
  }, [heroType]);

  // console.log('heroType', heroType);


  return (
    <div className={`modal ${isOpen ? "is-open" : ""}`}>
      <div className="modal-content">
        <div className="modal-header">
          <div className="hero-form">
            {/* <h3>{message}</h3> */}
            <h3 style={{ lineHeight: 1.4 }}>
              {message?.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </h3>
          </div>
          {/* Close Modal Button */}
          <button className="close-modal" onClick={onClose}>
            &times;
          </button>
        </div>
      </div>
    </div>
  );
}

export default HeroMessageModal;
